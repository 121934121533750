import * as React from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import Typography from '@mui/material/Typography';
import ImageListItem from '@mui/material/ImageListItem';

export default function StandardImageList() {
  return (
    <Box
      sx={{
        top: 0,
        left: 0,
        width: '65%',
        color: 'white',
        justifyContent: 'center',
        margin: 'auto',
        '@media (max-width: 600px)': {
          width: '100%',
        },
      }}
    >
      <Box
        sx={{
          top: 0,
          left: 0,
          width: '100%',
          height: '200px',
          bgcolor: '#F8E3A6',
          color: '#D5A211',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: 'auto',
          '@media (max-width: 600px)': {
            width: '100%',
            height: '100px',
          },
        }}
      >
        <Typography
          sx={{
            fontFamily: 'EB Garamond',
            fontSize: '45px',
            '@media (max-width: 600px)': {
              fontSize: '28px',
            },
          }}
        >
          Bon Appetit
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <ImageList sx={{ width: '100%', height: '100%' }} cols={3} rowHeight="auto" gap={7}>
          {itemData.map((item) => (
            <ImageListItem key={item.img}>
              <img
                src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                alt={item.title}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    </Box>
  );
}

const itemData = [
  {
    img: require("./media/food01.jpg"),
    title: 'Breakfast',
  },
  {
    img: require("./media/food02.jpg"),
    title: 'Lunch',
  },
  {
    img: require("./media/food03.jpg"),
    title: 'Dinner',
  },
  {
    img: require("./media/food04.jpg"),
    title: 'Dessert',
  },
  {
    img: require("./media/food05.jpeg"),
    title: 'Snack',
  },
  {
    img: require("./media/food06.jpg"),
    title: 'Appetizer',
  },
  {
    img: require("./media/food12.jpg"),
    title: 'Soup',
  },
  {
    img: require("./media/food08.jpg"),
    title: 'Salad',
  },
  {
    img: require("./media/food09.jpg"),
    title: 'Sandwich',
  },
  {
    img: require("./media/food11.JPG"),
    title: 'Burger',
  },
  {
    img: require("./media/food10.jpg"),
    title: 'Pizza',
  },
  {
    img: require("./media/food07.jpg"),
    title: 'Pasta',
  },
  {
    img: require("./media/food13.jpg"),
    title: 'Seafood',
  },
  {
    img: require("./media/food15.jpg"),
    title: 'Steak',
  },
  {
    img: require("./media/food14.jpg"),
    title: 'Sushi',
  },
  {
    img: require("./media/food16.jpg"),
    title: 'Ramen',
  },
  {
    img: require("./media/food17.jpg"),
    title: 'Curry',
  },
  {
    img: require("./media/food18.jpg"),
    title: 'BBQ',
  },
  {
    img: require("./media/food19.jpg"),
    title: 'Dim Sum',
  },
  {
    img: require("./media/food28.jpeg"),
    title: 'Taco',
  },
  {
    img: require("./media/food21.jpg"),
    title: 'Burrito',
  },
  {
    img: require("./media/food22.jpg"),
    title: 'Nachos',
  },
  {
    img: require("./media/food23.jpg"),
    title: 'Fried Chicken',
  },
  {
    img: require("./media/food24.jpg"),
    title: 'Wings',
  },
  {
    img: require("./media/food25.jpg"),
    title: 'Sausage',
  },
  {
    img: require("./media/food26.jpg"),
    title: 'Bacon',
  },
  {
    img: require("./media/food27.jpg"),
    title: 'Eggs Benedict',
  },
  {
    img: require("./media/food29.jpg"),
    title: 'Pancakes',
  },
  {
    img: require("./media/food20.jpg"),
    title: 'French Toast',
  },
  {
    img: require("./media/food30.jpg"),
    title: 'Waffles',
  },
  {
    img: require("./media/food31.jpeg"),
    title: 'Fruit Salad',
  },
  {
    img: require("./media/food32.jpg"),
    title: 'Yogurt',
  },
  {
    img: require("./media/food33.jpg"),
    title: 'Smoothie',
  },
];