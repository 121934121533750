import React from 'react';
import './Homepage.css';
import videoSource from './media/cover_video.mp4';

function HomePage() {
  return (
    <div className="home-container">
      {/* <div className = "video-container" autoPlay loop muted> */}
        <video className="video-background" autoPlay loop muted playsInline>
          <source src={videoSource} type="video/mp4" />
        </video>
        <div className="text-container">
          <h1 className="name">HELEN  YEUNG</h1> 
        </div>
      {/* </div> */}
    </div>
  );
}
export default HomePage;

