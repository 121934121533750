import React from "react";
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import './Calligraphy.css'
// import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';


const images = [
   {
    src: require("./media/calligraphy1.jpg"),
    width: 3655,
    height: 2355,
   },
   {
    src: require("./media/calligraphy6.jpg"),
    width: 2249,
    height: 3361,
   },
   {
    src: require("./media/calligraphy3.jpg"),
    width: 711,
    height: 1600,
   },
   {
    src: require("./media/calligraphy2.png"),
    width: 2076,
    height: 2463,
   },
   {
    src: require("./media/calligraphy5.jpg"),
    width: 1672,
    height: 980,
   },
   {
    src: require("./media/calligraphy4.png"),
    width: 342,
    height: 736,
   },
];



export default function Calligraphy() {
  const isSmallScreen = useMediaQuery('(max-width: 600px)');

  return (
    <Box sx={{ position: 'relative', height: '100vh' }}>
      {!isSmallScreen && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            height: '100%',
            width: '0.1px',
            backgroundColor: '#A1A1A1',
            zIndex: -1,
          }}
        ></div>
      )}

      <Box
        sx={{
          top: 0,
          left: 0,
          width: '65%',
          height: '200px',
          bgcolor: '#454545',
          color: 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: 'auto',
          '@media (max-width: 600px)': {
            width: '100%',
            height: '100px',
          },
        }}
      >
        <Typography sx={{ fontFamily: 'EB Garamond', fontSize: '45px',             
        '@media (max-width: 600px)': {
                fontSize: '28px'
            }}}>
          Calligraphy
        </Typography>
        <Typography
          sx={{
            fontFamily: 'EB Garamond, "Noto Serif SC", serif',
            fontSize: '32px',
            margin: '18px',
            '@media (max-width: 600px)': {
              fontSize: '28px'
          }
          }}
        > • </Typography>

        <Typography
          sx={{
            fontFamily: 'EB Garamond, "Noto Serif SC", serif',
            fontSize: '43px',
            '@media (max-width: 600px)': {
              fontSize: '28px'
          }
          }}> 書法
        </Typography>
      </Box>
      <Box sx={{ width: '60%', margin: 'auto' }}>
        <ImageList variant="masonry" cols={isSmallScreen ? 1 : 2} gap={60}>
          {images.map((image) => (
            <ImageListItem key={image.src}>
              <img
                src={`${image.src}?w=248&fit=crop&auto=format`}
                srcSet={`${image.src}?w=248&fit=crop&auto=format&dpr=2 2x`}
                loading="lazy"
                alt="calligraphy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    </Box>
  );
}
