import React from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import HomePage from './Homepage';
import NavigationBar from './NavigationBar'; 
import Calligraphy from './Calligraphy'; 
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Food from './Food'; 
import About from './About'; 
import About2 from './About2'; 
import { AddBoxOutlined } from '@mui/icons-material';

function App() {
  return (
    <div className="app-container">
      {/* NAVIGATION BAR */}
      <Router>
        <NavigationBar /> 
        <div className="content-container"> 
          <Switch>
            <Route exact path="/">
              <HomePage />
            </Route>
            <Route exact path="/calligraphy">
              <Calligraphy />
            </Route>
            <Route exact path="/blog">
              <Food />
            </Route>
            <Route exact path="/about">
              <About2 />
            </Route>
          </Switch>
        </div>
      </Router>

      {/* FOOTER */}
      <Box sx={{ 
        backgroundColor: '#ECECEC',
        color: 'white',
        height: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <Typography sx={{ 
          color: 'grey',
          fontFamily: 'Helvetica, sans-serif',
          fontSize: '14px',
          letterSpacing: '10px'
        }}>©</Typography>
        <Typography sx={{ 
          color: 'grey',
          fontFamily: 'Helvetica, sans-serif',
          fontSize: '10px',
          letterSpacing: '5px',
        }}>2023 BY HELEN YEUNG</Typography>
      </Box>
    </div>
  );
}

export default App;
