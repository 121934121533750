import React, {useState, useEffect} from "react";
import { styled } from '@mui/system';
// import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import profilePic from './media/profile.jpg';
import './About.css';

const FlashingText = styled('span')({
    animation: '$flashEffect 20s ease-in-out infinite',
   '@keyframes flashEffect' : {
      '0%': {
        opacity: 0,
      },
      '2%': {
        opacity: 1,
      },
      '98%': {
        opacity: 1,
      },
      '100%': {
        opacity: 0.5,
      },
    },
  });


  
  export default function About() {
    const [index, setIndex] = useState(0);
    const [texts, setTexts] = useState(['an artist', 'a traveler', 'a learner']);
  
    useEffect(() => {
      const interval = setInterval(() => {
        setIndex((index + 1) % texts.length);
      }, 5000);
      return () => clearInterval(interval);
    }, [index, texts]);
    // useEffect(() => {
    //     const interval = setInterval(() => {
    //       setIndex((prevIndex) => {
    //         const newIndex = (prevIndex + 1) % texts.length;
    //         return newIndex !== prevIndex ? newIndex : prevIndex;
    //       });
    //     }, 3000);
    //     return () => clearInterval(interval);
    //   }, [texts]);




    return (
        <Box sx={{ display: 'flex', marginLeft: '15%', marginTop: '3%', padding: '20px' }}>

        <Grid container spacing={2}>
        <Grid item xs={6}>
            {/* <div style={{ textAlign: 'left', 
                          top: '30%', 
                          fontSize: '3rem', 
                          fontFamily: 'EB Garamond', 
                          color: '#454545'
                          }}>
            <FlashingText> Hi, I'm Helen Yeung, {texts[index]}</FlashingText>
           
            </div> */}
            
            <div  style={{ textAlign: 'left', 
                          top: '30%', 
                          fontSize: '3rem', 
                          fontFamily: 'EB Garamond', 
                          color: '#454545'
                          }}>
            Hi, I'm Helen Yeung, <div className='fade-in-text' >{texts[index]} </div>
            </div>
            
            <div style={{ textAlign: 'left', 
                          letterSpacing: '2.5px', 
                          top: '30%', 
                          fontSize: '20px', 
                          color: '#454545'
                          }}>
            <p>I studied Computer Science and Finance at the University of Pennsylvania, and currently work for a long-short hedge fund. </p>

            I am always looking for aesthetics in life and building projects that excite me. 
            I occasionally post my artwork, travel itineraries, and random blog posts here. 
            <p> Email me at hmkyeung@hotmail.com if you would like to stay in touch! </p>
            </div>

          </Grid>

          <Grid item xs={4} style={{paddingTop: '4%'}}> 

            <div style={{ textAlign: 'right' }}>
              <img src={profilePic} alt="Your Name" style={{ borderRadius: '50%', width: '320px'}} />
            </div>
          </Grid>
        </Grid>
      </Box>
    );
  }
  
