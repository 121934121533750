// import React from 'react';
// import { Link } from 'react-router-dom'; 
// import './NavigationBar.css';

// function NavigationBar() {
//   return (
//     <nav className="NavigationBar">
//       {/* <div className="logo">
//         <p>HELEN YEUNG</p>
//       </div> */}
//       <ul>
//         <li>
//           <Link to="/">HOME</Link>
//         </li>  
//         <li>
//           <Link to="/calligraphy">CALLIGRAPHY</Link>  
//         </li> 
//         <li>
//           <Link to="/photography">PHOTOGRAPHY</Link>  
//         </li> 
//         <li>
//           <Link to="/about">ABOUT</Link>  
//         </li> 
//       </ul>
//     </nav>
//   );
// }
// export default NavigationBar;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; 
import './NavigationBar.css';

function NavigationBar() {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [visibleLinks, setVisibleLinks] = useState(4);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const updateVisibleLinks = () => {
    const width = window.innerWidth;
    if (width < 768) {
      setVisibleLinks(2); // Show only 2 links on small screens
    } else {
      setVisibleLinks(4); // Show all links on larger screens
    }
  };

  useEffect(() => {
    updateVisibleLinks();
    window.addEventListener('resize', updateVisibleLinks);
    return () => window.removeEventListener('resize', updateVisibleLinks);
  }, []);

  const links = [
    { to: "/", label: "HOME" },
    { to: "/calligraphy", label: "CALLIGRAPHY" },
    { to: "/blog", label: "BLOG" },
    { to: "/about", label: "ABOUT" }
  ];

  return (
    <nav className="NavigationBar">
      <ul className="nav-links">
        {links.slice(0, visibleLinks).map((link, index) => (
          <li key={index}>
            <Link to={link.to}>{link.label}</Link>
          </li>
        ))}
      </ul>
      <div className="hamburger-menu" onClick={toggleDropdown}>
        &#x22EE; {/* Three vertical dots */}
      </div>
      <ul className={`dropdown-menu ${dropdownVisible ? 'show' : ''}`}>
        <li className="close-button" onClick={toggleDropdown}>
          &#x2715; {/* Cross symbol */}
        </li>
        {links.map((link, index) => (
          <li key={index}>
            <Link to={link.to} onClick={toggleDropdown}>{link.label}</Link>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default NavigationBar;