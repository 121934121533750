import React, { useState, useEffect } from "react";
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import profilePic from './media/profile.jpg';
import './About2.css';

export default function About2() {
  const [index, setIndex] = useState(0);
  const [showText, setShowText] = useState(true);
  const [texts, setTexts] = useState(['— artist', '— calligrapher', '— traveler']);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((index + 1) % texts.length);
      setShowText(false);
    }, 5000);
    return () => clearInterval(interval);
  }, [index, texts]);

  useEffect(() => {
    setShowText(true);
  }, [showText]);

  return (
    <Box sx={{ 
      display: 'flex', 
      marginLeft: '15%', 
      '@media (max-width: 600px)': {
        marginLeft: '1%',
        marginTop: '8%'
      }, 
      marginTop: '5%', 
      padding: '20px' 
    }}>
      <div className="container">
        <div className="title">
          HELEN YEUNG
          {showText &&
            <div className='fade-in-text'>{texts[index]}</div>
          }
        </div>
        <div className="description">
          <p>Born and raised in Hong Kong. Based in New York.</p>
          <p>Trained under Mr. Lam Ping Sun for over a decade.</p>
          <p>Specialty in calligraphy and fusion ink wash.</p>
          <p>Contact: hmkyeung@hotmail.com</p>
        </div>
      </div>
    </Box>
  );
}


// import React, { useState, useEffect } from "react";
// import { styled } from '@mui/system';
// import Box from '@mui/material/Box';
// import Grid from '@mui/material/Grid';
// import profilePic from './media/profile.jpg';
// import './About2.css';

// const FlashingText = styled('span')({
//   animation: '$flashEffect 20s ease-in-out infinite',
//   '@keyframes flashEffect': {
//     '0%': {
//       opacity: 0,
//     },
//     '2%': {
//       opacity: 1,
//     },
//     '98%': {
//       opacity: 1,
//     },
//     '100%': {
//       opacity: 0.5,
//     },
//   },
// });


// export default function About() {
//   const [index, setIndex] = useState(0);
//   const [showText, setShowText] = useState(true);
//   const [texts, setTexts] = useState(['— artist', '— calligrapher', '— traveler']);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setIndex((index + 1) % texts.length);
//       setShowText(false);
//     }, 5000);
//     return () => clearInterval(interval);
//   }, [index, texts]);

//   useEffect(() => {
//     setShowText(true);
//   }, [showText]);

//   return (
//     <Box sx={{ display: 'flex', marginLeft: '15%',          '@media (max-width: 600px)': {
//       marginLeft: '1%'
//     }, marginTop: '5%', padding: '20px' }}>

//       {/* <Grid container spacing={2}>
//         <Grid item xs={6}> */}
//         <div style={{ 
//   display: 'flex', 
//   flexDirection: 'column', 
//   alignItems: 'flex-start',
//   // marginTop: '30%' 

// }}>
//           <div style={{
//             textAlign: 'left',
//             top: '30%',
//             fontSize: '3rem',  

//             fontFamily: 'EB Garamond',
//             color: '#454545',
//             letterSpacing: '0.5rem',

//           }}>
//             HELEN YEUNG
//             {showText &&
//               <div className='fade-in-text' style={{letterSpacing: '1px', fontSize: '3rem' }}>{texts[index]} </div>
//             }
//           </div>

//           <div style={{
//             textAlign: 'justify',
//             fontFamily: 'Calibri Light',
//             letterSpacing: '1.5px',
//             fontWeight: '200',
//             fontStretch: 'expanded',
//             top: '30%',
//             fontSize: '23.5px',
//             color: 'grey',
//             marginTop: '20px'
//           }}>
//             <p>Born and raised in Hong Kong. Based in New York.</p>
//             <p> Trained under Mr. Lam Ping Sun for over a decade. </p>
//             Specialty in calligraphy and fusion ink wash. 
//             <p> Contact: hmkyeung@hotmail.com </p>
//           </div>
//           </div>
//         {/* </Grid> */}

//         {/* <Grid item xs={4} style={{ paddingTop: '2%' }}>

//           <div style={{ textAlign: 'right' }}>
//             <img src={profilePic} alt="Your Name" style={{ borderRadius: '50%', width: '310px' }} />
//           </div>
//         </Grid> */}
//       {/* </Grid> */}

//     </Box>
//   );
//   }
  
